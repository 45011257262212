import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Routes from "../routes"
import { useImpression } from "../analytics/utils"

const BestDebateMoments = ({ location }) => {
  useImpression("BestDebateMoments")
  return (
    <Layout location={location}>
      <Seo
        title="Best Debates: Catholic, Muslim, Atheist, Agnostic"
        description="Here are the best debates moments with religious people versus atheists and scientists."
      />
      <h1>Best Religious and Atheist Debate Moments</h1>
      <p>
        A lot of these clips come from debates found on{" "}
        <Link to={Routes.DEBATES}>our debates page</Link>. So if anything
        catches your interest, be sure to check out the full religious debate
        there.
      </p>
      <hr />
      <p>
        Now, this first clip comes from a debate with{" "}
        <Link to={Routes.DAWKINS}>Richard Dawkins</Link> and the infamous{" "}
        <Link to={Routes.GEORGE_PELL}>George Pell</Link>. Pell gets caught by
        the moderator, Tony Jones, for asserting that Jews have been
        intellectually inferior in the past. The moderator quickly asks Pell if
        Jesus is included in that generalization, as he was also a Jew. Pell
        clearly didn't think this one through.
      </p>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        src="https://www.youtube.com/embed/y8hy8NxZvFY?start=1151"
        title="Tony Jones catches George Pell in a backwards claim."
        width="560"
      ></iframe>
      <p>
        A little while later, within the same debate, George Pell is again
        called out by a video caller. The caller asks Pell, "As a climate change
        skeptic, you demand a very high standard of evidence that global warming
        has an anthropogenic cause. My question is, why then do you not require
        the same standard of evidence for the existence of God?"
      </p>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        src="https://www.youtube.com/embed/y8hy8NxZvFY?start=2190"
        title="Video caller asks George Pell about maintaining a standard of evidence regarding climate change and faith."
        width="560"
      ></iframe>
      <p>
        Pell seems to trip up again towards the end of this debate. He starts
        saying how he believes the consecration turns the bread and wine into
        the body and blood of Christ. Dawkins responds with, "English is my
        native language. The wafer does not become the body of anybody in the
        English language". Although Pell appeared to imply that he doesn't know
        scientifically how it would have happened, but his belief in God is
        enough to confirm that it does indeed happen. So, here we have another
        example of a belief that is unsupported by evidence.
      </p>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        src="https://www.youtube.com/embed/y8hy8NxZvFY?start=2786"
        title="Pell claims the Eucharist is truthful, yet does not accurately describe how that can be true in the English language."
        width="560"
      ></iframe>
      <hr />
      <p>
        Check out our other page to learn more about the{" "}
        <Link to={Routes.TEN_COMMANDMENTS}>Ten Commandments</Link>.
      </p>
    </Layout>
  )
}

export default BestDebateMoments
